export default function usePermissions() {
    const { user } = useUserSession();

    // 0 EUR
    // 1 GBP
    // 2 CNY
    // 3 RUB
    // 4 USD
    // 5 UK_EURO
    const listino = useCookie("price-list", { default: () => "0" });

    const permissions = {
        has_admin:
            user?.value?.profile?.user_metadata?.crm_data?.has_admin === true,
        has_tech_catalog:
            user?.value?.profile?.user_metadata?.crm_data?.has_tech_catalog ??
            false,
        has_any_prices:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_cny ||
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_gbp ||
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_rub ||
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_usd ||
            user?.value?.profile?.user_metadata?.crm_data?.has_public_prices ||
            false,
        has_prices:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices ?? false,
        has_prices_cny:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_cny ??
            false,
        has_prices_gbp:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_gbp ??
            false,
        has_prices_rub:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_rub ??
            false,
        has_prices_usd:
            user?.value?.profile?.user_metadata?.crm_data?.has_prices_usd ??
            false,
        has_public_prices:
            user?.value?.profile?.user_metadata?.crm_data?.has_public_prices ??
            false,
        has_quick_ship:
            user?.value?.profile?.user_metadata?.crm_data?.has_quick_ship ??
            false,
    };

    function checkListino(value: string) {
        if (value == "" && permissions.has_any_prices == true) {
            listino.value = "0";
        }
        if (value == "0" && permissions.has_public_prices == false) {
            listino.value = "1";
        }
        if (value == "1" && permissions.has_prices_gbp == false) {
            listino.value = "2";
        }
        if (value == "2" && permissions.has_prices_cny == false) {
            listino.value = "3";
        }
        if (value == "3" && permissions.has_prices_rub == false) {
            listino.value = "4";
        }
        if (value == "4" && permissions.has_prices_usd == false) {
            listino.value = "";
        }
        if (
            ["1", "2", "3", "4", "5"].includes(value) == false &&
            permissions.has_any_prices == true
        ) {
            listino.value = "0";
        }
    }

    checkListino(listino.value);
    watch(listino, (v) => checkListino(v));

    return {
        listino,
        ...permissions,
    };
}
