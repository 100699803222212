import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPage, LazyCta, LazyImageItem, LazyImagesList, LazySimpleCta, LazySimpleLink, LazySimpleText, LazyRenderCacheable, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["Page", LazyPage],
["Cta", LazyCta],
["ImageItem", LazyImageItem],
["ImagesList", LazyImagesList],
["SimpleCta", LazySimpleCta],
["SimpleLink", LazySimpleLink],
["SimpleText", LazySimpleText],
["RenderCacheable", LazyRenderCacheable],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
